import React, { useState } from 'react'
import BottomTabBar from '../components/bottomTab'
import { CouponList, Dialog,  Popup, Sidebar ,Toast} from 'react-vant';
import { shopStore } from '../database/shopInfo';

import "../static/css/kind.css"
import axios from 'axios';
export default function KindS() {


  const [active, setActive] = useState(0);

  const { kind2List,kind1List,kind3List,kind4List} = shopStore()


  const data3 = [
    {
      id: 1,
      condition: '无使用门槛\n最多优惠12元',
      value: 150,
      name: '优惠券名称',
      startAt: 1489104000,
      endAt: 1514592000,
      valueDesc: '1.5',
      unitDesc: '元',
    },
    {
      id: 1,
      condition: '无使用门槛\n最多优惠12元',
      value: 150,
      name: '优惠券名称',
      startAt: 1489104000,
      endAt: 1514592000,
      valueDesc: '1.5',
      unitDesc: '元',
    }
]

  

const [coupons, setCoupons] = useState(data3)
const [chosenCoupon, setChosenCoupon] = useState(-1)
const [visible, setVisible] = useState(false)

const onChange = (index) => {
  setVisible(false)
  setChosenCoupon(index)
  console.log(index);
  Dialog.confirm({
    title: '订单确认',
    message: '您是否确定购买，若选择确定，小车将会立即给您送货',
  })
    .then(async() => {
      try {
        const response = await 
        axios.get(`http://127.0.0.1:7001/robot`)
        .then(()=>{console.log(2222);})
      } catch (error) {
        console.error('消息发送失败', error);
      }
    })
    .catch(() => {
      console.log('catch')
    })
}




  
  return (
    <div>
        <Sidebar
          value={active}
          onChange={(v) => {
            setActive(v);
            Toast.info(`内容区 ${v + 1}`);
          }}
        >
          <Sidebar.Item contentStyle={{ backgroundColor: '#fff', padding: '18px 10px' }} title="大会吉祥物">
            111
          </Sidebar.Item>
          <Sidebar.Item contentStyle={{ backgroundColor: '#fff', padding: '18px 10px' }} title="内容2">
222
          </Sidebar.Item>
          <Sidebar.Item contentStyle={{ backgroundColor: '#fff', padding: '18px 10px' }} title="内容3">
            
222
          </Sidebar.Item>
          <Sidebar.Item contentStyle={{ backgroundColor: '#fff', padding: '18px 10px' }} title="内容3">
333
          </Sidebar.Item>
          


        </Sidebar>
        <BottomTabBar/>
      <Popup
          round
          position='bottom'
          style={{ height: '90%', paddingTop: 4 }}
          visible={visible}
          onClose={() => setVisible(false)}
        >
        <CouponList
          chosenCoupon={chosenCoupon}
          coupons={coupons}
          // disabledCoupons={disabledCoupons}
          onChange={onChange}
          closeButtonText={"取消选择"}
          showExchangeBar={false}
        />
      </Popup>
    </div>
  )
}
