

import { create } from 'zustand'//导入zustand的create方法

export const shopStore = create((set) => ({
    kind1List:[
    {num:2,price:22,description:"hahaha",title:"测试商品111",link:"https://img.yzcdn.cn/vant/ipad.jpeg",
    tag:["食品","临时使用"],tagTitle:"热销"},
    {num:2,price:22,description:"hahaha",title:"测试商品111",link:"https://img.yzcdn.cn/vant/ipad.jpeg",
    tag:["食品","临时使用"],tagTitle:"热销"},
    {num:2,price:22,description:"hahaha",title:"测试商品111",link:"https://img.yzcdn.cn/vant/ipad.jpeg",
    tag:["食品","临时使用"],tagTitle:"热销"},
    {num:2,price:22,description:"hahaha",title:"测试商品111",link:"https://img.yzcdn.cn/vant/ipad.jpeg",
    tag:["食品","临时使用"],tagTitle:"热销"},
    {num:2,price:22,description:"hahaha",title:"测试商品111",link:"https://img.yzcdn.cn/vant/ipad.jpeg",
    tag:["食品","临时使用"],tagTitle:"热销"},
    {num:2,price:22,description:"hahaha",title:"测试商品111",link:"https://img.yzcdn.cn/vant/ipad.jpeg",
    tag:["食品","临时使用"],tagTitle:"热销"},
    {num:2,price:22,description:"hahaha",title:"测试商品111",link:"https://img.yzcdn.cn/vant/ipad.jpeg",
    tag:["食品","临时使用"],tagTitle:"热销"},
    {num:2,price:22,description:"hahaha",title:"测试商品111",link:"https://img.yzcdn.cn/vant/ipad.jpeg",
    tag:["食品","临时使用"],tagTitle:"热销"},

    ],
    kind2List:[
        {num:2,price:22,description:"hahaha",title:"测试商品111",link:"https://img.yzcdn.cn/vant/ipad.jpeg",
        tag:["食品","临时使用"],tagTitle:"热销"},
        {num:2,price:22,description:"hahaha",title:"测试商品111",link:"https://img.yzcdn.cn/vant/ipad.jpeg",
        tag:["食品","临时使用"],tagTitle:"热销"},
        {num:2,price:22,description:"hahaha",title:"测试商品111",link:"https://img.yzcdn.cn/vant/ipad.jpeg",
        tag:["食品","临时使用"],tagTitle:"热销"},
        {num:2,price:22,description:"hahaha",title:"测试商品111",link:"https://img.yzcdn.cn/vant/ipad.jpeg",
        tag:["食品","临时使用"],tagTitle:"热销"},
        {num:2,price:22,description:"hahaha",title:"测试商品111",link:"https://img.yzcdn.cn/vant/ipad.jpeg",
        tag:["食品","临时使用"],tagTitle:"热销"},
        {num:2,price:22,description:"hahaha",title:"测试商品111",link:"https://img.yzcdn.cn/vant/ipad.jpeg",
        tag:["食品","临时使用"],tagTitle:"热销"},
        {num:2,price:22,description:"hahaha",title:"测试商品111",link:"https://img.yzcdn.cn/vant/ipad.jpeg",
        tag:["食品","临时使用"],tagTitle:"热销"},
        {num:2,price:22,description:"hahaha",title:"测试商品111",link:"https://img.yzcdn.cn/vant/ipad.jpeg",
        tag:["食品","临时使用"],tagTitle:"热销"},
    ],
    kind3List:[
        {num:2,price:22,description:"hahaha",title:"测试商品111",link:"https://img.yzcdn.cn/vant/ipad.jpeg",
        tag:["食品","临时使用"],tagTitle:"热销"},
        {num:2,price:22,description:"hahaha",title:"测试商品111",link:"https://img.yzcdn.cn/vant/ipad.jpeg",
        tag:["食品","临时使用"],tagTitle:"热销"},
        {num:2,price:22,description:"hahaha",title:"测试商品111",link:"https://img.yzcdn.cn/vant/ipad.jpeg",
        tag:["食品","临时使用"],tagTitle:"热销"},
        {num:2,price:22,description:"hahaha",title:"测试商品111",link:"https://img.yzcdn.cn/vant/ipad.jpeg",
        tag:["食品","临时使用"],tagTitle:"热销"},
        {num:2,price:22,description:"hahaha",title:"测试商品111",link:"https://img.yzcdn.cn/vant/ipad.jpeg",
        tag:["食品","临时使用"],tagTitle:"热销"},
        {num:2,price:22,description:"hahaha",title:"测试商品111",link:"https://img.yzcdn.cn/vant/ipad.jpeg",
        tag:["食品","临时使用"],tagTitle:"热销"},
        {num:2,price:22,description:"hahaha",title:"测试商品111",link:"https://img.yzcdn.cn/vant/ipad.jpeg",
        tag:["食品","临时使用"],tagTitle:"热销"},
        {num:2,price:22,description:"hahaha",title:"测试商品111",link:"https://img.yzcdn.cn/vant/ipad.jpeg",
        tag:["食品","临时使用"],tagTitle:"热销"},
    ],
    kind4List:[
        {num:2,price:22,description:"hahaha",title:"测试商品111",link:"https://img.yzcdn.cn/vant/ipad.jpeg",
        tag:["食品","临时使用"],tagTitle:"热销"},
        {num:2,price:22,description:"hahaha",title:"测试商品111",link:"https://img.yzcdn.cn/vant/ipad.jpeg",
        tag:["食品","临时使用"],tagTitle:"热销"},
        {num:2,price:22,description:"hahaha",title:"测试商品111",link:"https://img.yzcdn.cn/vant/ipad.jpeg",
        tag:["食品","临时使用"],tagTitle:"热销"},
        {num:2,price:22,description:"hahaha",title:"测试商品111",link:"https://img.yzcdn.cn/vant/ipad.jpeg",
        tag:["食品","临时使用"],tagTitle:"热销"},
        {num:2,price:22,description:"hahaha",title:"测试商品111",link:"https://img.yzcdn.cn/vant/ipad.jpeg",
        tag:["食品","临时使用"],tagTitle:"热销"},
        {num:2,price:22,description:"hahaha",title:"测试商品111",link:"https://img.yzcdn.cn/vant/ipad.jpeg",
        tag:["食品","临时使用"],tagTitle:"热销"},
        {num:2,price:22,description:"hahaha",title:"测试商品111",link:"https://img.yzcdn.cn/vant/ipad.jpeg",
        tag:["食品","临时使用"],tagTitle:"热销"},
        {num:2,price:22,description:"hahaha",title:"测试商品111",link:"https://img.yzcdn.cn/vant/ipad.jpeg",
        tag:["食品","临时使用"],tagTitle:"热销"},
    ],
    
  	addUserInfo: (val) => {    
        set((state)=>({kind1List:val}));
  	},//定义仓库的相应reducer


    
    
}))
