import routerTab from "./router";
import { useRoutes } from "react-router-dom";
// import "./static/css/global.css"

function App() {

  const routerMap=useRoutes(routerTab);//注册前端路由表


  return (
    <div>
      {routerMap}
    </div>
  );
}

export default App;
