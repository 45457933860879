import { Navigate } from 'react-router-dom'
import KindS from './pages/KindS';
import UserPage from './pages/UserPage';
import Landing from './pages/Landing';



let routerTab=[
	{
		path:'/user',
		element:<UserPage/>
	},
    {
		path:'/kind',
		element:<KindS/>
	},
    {
		path:'/landing',
		element:<Landing/>
	},
    {
		index:true,
		element:<Navigate to="/landing"/>
	},//路由重定向到登录界面



]


export default routerTab;