import React from 'react'
import BottomTabBar from '../components/bottomTab'

export default function UserPage() {
  return (
    <div>
        <BottomTabBar/>
    </div>
  )
}
