import React, { useEffect, useState } from 'react'
import BottomTabBar from '../components/bottomTab'
import {CouponList, Tabs,Search, Swiper,Toast, ProductCard, Tag, Popup, Dialog } from 'react-vant'
import { shopStore } from '../database/shopInfo';
import "../static/css/landing.css"
import axios from 'axios';

export default function Landing() {
  const [value, setValue] = useState('');
  const { kind2List,kind1List,kind3List,kind4List} = shopStore()

  const colors = ['#ace0ff', '#bcffbd', '#e4fabd', '#ffcfac']


  const items = colors.map((color, index) => (
    <Swiper.Item key={color}>
      <div
        onClick={() => {
          Toast.info(`你点击了卡片 ${index + 1}`)
        }}
      >
        {index + 1}
      </div>
    </Swiper.Item>
  ))


  
  const data3 = [
    {
      id: 1,
      condition: '无使用门槛\n最多优惠12元',
      value: 150,
      name: '优惠券名称',
      startAt: 1489104000,
      endAt: 1514592000,
      valueDesc: '1.5',
      unitDesc: '元',
    },
    {
      id: 2,
      condition: '无使用门槛\n最多优惠12元',
      value: 150,
      name: '优惠券名称',
      startAt: 1489104000,
      endAt: 1514592000,
      valueDesc: '1.5',
      unitDesc: '元',
    }
]


const [coupons, setCoupons] = useState(data3)
const [chosenCoupon, setChosenCoupon] = useState(-1)
const [visible, setVisible] = useState(false)

const onChange = (index) => {
  setVisible(false)
  setChosenCoupon(index)
  console.log(index);
  Dialog.confirm({
    title: '订单确认',
    message: '您是否确定购买，若选择确定，小车将会立即给您送货',
  })
    .then(async() => {
      try {
        const response = await 
        axios.get(`http://101.42.225.134:7001/robot`)
        .then(()=>{console.log(2222);})
      } catch (error) {
        console.error('消息发送失败', error);
      }
    })
    .catch(() => {
      console.log('catch')
    })
}






  const handleTabChange=(val)=>{
    
  }




  return (
    <div>
          <Search
          shape="round"
          background="#4fc08d"
          value={value}
          onChange={setValue}
          placeholder="请输入搜索关键词"
        />

      <div className="demo-swiper">
        <Swiper autoplay={5000}
            sticky
            scrollspy={{ autoFocusLast: true, reachBottomThreshold: 50 }}
        >{items}</Swiper>
      </div>
        <Tabs active={1}
        onChange={(val)=>{handleTabChange(val)}}>
            <Tabs.TabPane title='食品'>
              {
                kind1List.map((val,index)=>{
                  return     <ProductCard
                  tag={val.tagTitle}
                  num={val.num}
                  price={val.price}
                  originPrice="10.00"
                  desc={val.description}
                  title={val.title}
                  thumb={val.link}
                  onClick={()=>{setVisible(true)}}
                  footer={
                    <>
                      {
                        val.tag.map((value)=>{
                          return <Tag plain type="danger" style={{ marginRight: 5 }}>
                          {value}
                        </Tag>
                        })
                      }
                    </>
                  }
                />
                })
              }
            </Tabs.TabPane>
            <Tabs.TabPane title='饮品' >
              {
                kind2List.map((val,index)=>{
                  return     <ProductCard
                  tag={val.tagTitle}
                  num={val.num}
                  price={val.price}
                  originPrice="10.00"
                  desc={val.description}
                  title={val.title}
                  thumb={val.link}
                  footer={
                    <>
                      {
                        val.tag.map((value)=>{
                          return <Tag plain type="danger" style={{ marginRight: 5 }}>
                          {value}
                        </Tag>
                        })
                      }
                    </>
                  }
                />
                })
              }
            </Tabs.TabPane>
            <Tabs.TabPane title='电子产品'>
              {
                kind3List.map((val,index)=>{
                  return     <ProductCard
                  tag={val.tagTitle}
                  num={val.num}
                  price={val.price}
                  originPrice="10.00"
                  desc={val.description}
                  title={val.title}
                  thumb={val.link}
                  footer={
                    <>
                      {
                        val.tag.map((value)=>{
                          return <Tag plain type="danger" style={{ marginRight: 5 }}>
                          {value}
                        </Tag>
                        })
                      }
                    </>
                  }
                />
                })
              }
            </Tabs.TabPane>
            <Tabs.TabPane title='临时衣物'>
              {
                kind4List.map((val,index)=>{
                  return     <ProductCard
                  tag={val.tagTitle}
                  num={val.num}
                  price={val.price}
                  originPrice="10.00"
                  desc={val.description}
                  title={val.title}
                  thumb={val.link}
                  footer={
                    <>
                      {
                        val.tag.map((value)=>{
                          return <Tag plain type="danger" style={{ marginRight: 5 }}>
                          {value}
                        </Tag>
                        })
                      }
                    </>
                  }
                />
                })
              }
            </Tabs.TabPane>
        </Tabs>


        <BottomTabBar/>
        <Popup
          round
          position='bottom'
          style={{ height: '90%', paddingTop: 4 }}
          visible={visible}
          onClose={() => setVisible(false)}
        >
        <CouponList
          chosenCoupon={chosenCoupon}
          coupons={coupons}
          // disabledCoupons={disabledCoupons}
          onChange={onChange}
          closeButtonText={"取消选择"}
          showExchangeBar={false}
        />
      </Popup>
    </div>


  )
}
